import { sortBy } from 'lodash';

export default {
  getAgreement: (state) => state.agreement,
  sortedAgreementHeaders: (state) => sortBy(state.agreementHeaders, 'agreementNumber').reverse(),
  stagesForActions: (state, getters, rootState, rootGetters) => {
    const isInRole = rootGetters['auth/isInRole'];
    const isAdmin = isInRole('Administrators');
    if (isAdmin) {
      return state.stagesForActions.map((stage) => ({
        ...stage,
        disabled: false,
      }));
    }
    return state.stagesForActions;
  },
};
