<template>
  <v-app>
    <Auth />
    <v-navigation-drawer v-if="isLoggedIn" v-model="drawer" color="primary" app>
      <v-divider />
      <MenuDrawer />
      <v-divider />
      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <v-app-bar v-if="isLoggedIn" color="primary" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="{ name: 'app-Home' }">
        <v-img src="./assets/logo_dark.png" contain :height="60" alt="logo" />
      </router-link>
      <SearchBar />
      <v-spacer></v-spacer>
      <CurrencyMenu v-if="showCurrencyMenu" />
      <div class="mr-2" />
      <OrganizationMenu v-if="organization" />
      <div class="mr-2 ml-2 hidden-sm-and-down">
        <Account />
      </div>
      <Feedback />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" text outlined v-bind="attrs" v-on="on">
            <v-icon>{{ mdiCog }}</v-icon>
          </v-btn>
        </template>
        <AdminMenuDrawer />
      </v-menu>
    </v-app-bar>

    <v-main>
      <Breadcrumbs />
      <router-view></router-view>
    </v-main>

    <v-footer padless dense color="primary" dark>
      <v-col class="text-center text-caption" cols="12">
        Copyright &copy; {{ new Date().getFullYear() }} <strong>Funancial (Pty) Ltd</strong>
        <v-spacer></v-spacer>
        <div style="font-size: smaller">v{{ appVersion }}</div>
      </v-col>
    </v-footer>

    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="error" />
    <BasicToasts />
    <LiveUpdates v-if="hasLiveUpdates" />
  </v-app>
</template>

<script>
import { components as auroraAppCore, authHub, errorHub } from '@codehq/aurora-app-core/src';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { clarity } from 'clarity-js';
// import { VueRootMixin } from '@codehq/aurora-telemetry-client';
import { mdiCog, mdiHome } from '@mdi/js';
import Account from './components/Account.vue';
import AdminMenuDrawer from './components/AdminMenuDrawer.vue';
import Auth from './components/Auth.vue';
import Breadcrumbs from './components/Breadcrumbs.vue';
import CurrencyMenu from './components/CurrencyMenu.vue';
import Feedback from './components/Feedback.vue';
import LiveUpdates from './components/LiveUpdates.vue';
import MenuDrawer from './components/MenuDrawer.vue';
import OrganizationMenu from './components/OrganizationMenu.vue';
import SearchBar from './components/SearchBar.vue';
import menuItems from './menuItems';
import './site.scss';
import AppVersion from './version.txt';

const { BasicToasts, Toasts } = auroraAppCore;

export default {
  name: 'App',
  components: {
    Account,
    AdminMenuDrawer,
    Auth,
    BasicToasts,
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumbs,
    Feedback,
    MenuDrawer,
    CurrencyMenu,
    LiveUpdates,
    OrganizationMenu,
    SearchBar,
    // OrganizationSelect,
    Toasts,
  },
  mixins: [menuItems],
  data() {
    return {
      appVersion: AppVersion,
      message: '',
      error: '',
      drawer: true,
      mdiHome,
      mdiCog,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('auth', ['token', 'username', 'userId']),
    ...mapState('organizations', ['organization']),
    showCurrencyMenu() {
      return (
        this.$route.name === 'app-Home' ||
        this.$route.name === 'contracts-ListContract' ||
        this.$route.name === 'solutions-ListSolution' ||
        this.$route.name === 'solutions-EditSolution' ||
        this.$route.name === 'contracts-ReportGpPerMonth' ||
        this.$route.name === 'contracts-Reports' ||
        this.$route.name === 'quotes-EditClient'
      );
    },
    topMenuItems() {
      return this.menuItems.filter((m) => m.topMenu === true);
    },
    hasLiveUpdates() {
      return false;
      // return this.isLoggedIn
      //   && this.$route.name !== 'app-OnboardXero'
      //   && this.$route.name !== 'app-Onboard'
      //   && this.$route.name !== 'app-Init';
    },
  },
  watch: {
    $route: {
      handler() {
        clarity.set('App', this.appVersion);
      },
      immediate: true,
    },
  },
  async mounted() {
    this.$log.debug('App mounted');
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.drawer = true;
    }

    // this.$telemetry.browserTelemetry.track();
    // this.$vuetify.theme.dark = true;
    if (this.isLoggedIn) {
      await this.LOAD_organizations();
      clarity.consent();
      clarity.identify(this.userId, this.appVersion, this.$route.path, this.username);
      clarity.set('App', this.appVersion);
      clarity.event('version', this.appVersion);
    } else {
      clarity.identify('anonymous', 'app-session', this.$route.path);
      clarity.set('App', this.appVersion);
    }
    this.$root.$on('organizations.loaded', (organizations) => {
      if (organizations.length === 0 && this.isLoggedIn) {
        this.$router.push({
          name: 'app-Onboard',
        });
      }
    });
    errorHub.$on('network.error', () => {
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', () => {
      this.LOGOUT_auth();
      this.$root.$emit('auth:logoff');
    });
    this.$root.$on('toast:error', (err) => {
      this.error = '';
      this.error = err;
    });
    this.$root.$on('auth:login', async () => {
      this.$log.debug('auth:login');
      await this.LOAD_organizations();
      // const { $route } = this;
      // console.debug($route);
      this.$root.$emit('toast:notify', 'Logged in successfully');
      await this.$router.push({ name: 'app-Init' });
      clarity.identify(this.userId, this.appVersion, this.$route.path, this.username);
      clarity.event('login', this.username);
      clarity.set('App', this.appVersion);
      // if ($route.redirect) {
      //   this.$router.push({
      //     name: 'app-Init',
      //     query: {
      //       redirect: $route.query.redirect,
      //       query: $route.query.query,
      //     },
      //   });
      // } else {
      //   await this.$router.push({ name: 'app-Init' });
      // }
    });
    this.$root.$on('auth:logoff', async () => {
      this.LOGOUT_auth();
      this.$root.$emit('toast:notify', 'Logged out successfully');
      clarity.event('logoff', this.username);
      // const { $route } = this;
      // const query = { };
      // if (!$route.path.includes('/auth')) {
      //   query.redirect = $route.path;
      //   query.query = $route.query;
      // }
      await this.$router.push({
        path: '/auth/login',
        // query,
      });
      window.location.reload();
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    this.$root.$off('auth:login');
    authHub.$off('user.unauthorized');
    errorHub.$off('network.error');
    this.$root.$off('auth:logoff');
    this.$root.$off('toast:error');
    this.$root.$off('organizations.loaded');
    this.$log.debug('App destroyed');
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth']),
    ...mapActions('organizations', ['LOAD_organizations']),
    ...mapMutations('organizations', ['SET_organization']),
    abortAxios() {
      this.$root.$abortAxios();
    },
  },
  // watch: {
  //   $route: {
  //     handler() {
  //       this.$root.$abortAxios();
  //     },
  //   },
  //   deep: true,
  // },
};
</script>
