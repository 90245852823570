<template>
  <v-row>
    <v-col cols="4" class="pt-5">
      <h4 v-if="!Open">
        <v-icon v-if="Agreement.verified" color="success">
          {{ mdiSeal }}
        </v-icon>
        {{ Agreement.agreementNumber }}
        <v-icon small title="Copy to clipboard" @click.stop="copyToClipboard(Agreement.agreementNumber)">
          {{ mdiContentCopy }}
        </v-icon> |
        {{ Agreement.reference }}
        <v-icon small title="Copy to clipboard" @click.stop="copyToClipboard(Agreement.reference)">
          {{ mdiContentCopy }}
        </v-icon>
        <span v-if="Agreement.childId">
          | Upgraded
        </span>
        <v-icon v-if="!Agreement.isActive" class="ml-2" color="error">
          {{ mdiArchive }}
        </v-icon>
      </h4>
      <h4 v-else>
        <v-icon v-if="Agreement.verified" color="success">
          {{ mdiSeal }}
        </v-icon>
        {{ Agreement.agreementNumber }}
        <span v-if="Agreement.childId">
          | Upgraded
        </span>
        <v-icon v-if="!Agreement.isActive" class="ml-2">
          {{ mdiArchive }}
        </v-icon>
      </h4>
    </v-col>
    <v-col cols="2" class="pt-5">
      {{ formatDate(Agreement.updated) }}
    </v-col>
    <v-col cols="2" class="pt-5">
      <span v-if="Agreement.status !== 'Delivered'">
        Not yet delivered
      </span>
      <span v-else-if="SelectedDate === 'Renewal Date'">
        {{ formatDate(Agreement.renewalDate) }}
      </span>
      <span v-else-if="SelectedDate === 'Delivered Date'">
        {{ formatDate(Agreement.deliveredDate) }}
      </span>
      <span v-else-if="SelectedDate === 'Notice Date'">
        {{ formatDate(Agreement.noticeDate) }}
      </span>
    </v-col>
    <v-col v-if="summary" cols="2" class="pt-5 text-right">
      {{ formatCurrency(summary.totalProfit, Agreement.currencyCode) }}
    </v-col>
    <v-col cols="1" class="pt-3 text-right">
      <RocketMenu :items="availableActions" @click="onMenuClick" :small="true" />
      <ModalPrint ref="ModalPrint" :Agreement="localAgreement" />
      <ModalMigrateAgreement ref="ModalMigrateAgreement" :Agreement="localAgreement" />
      <AuditDialog
        :entity-id="Agreement.id"
        entity-type="Agreement"
        :dialog="dialogAudit"
        @close="dialogAudit = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import { mdiContentCopy, mdiArchive, mdiSeal } from '@mdi/js';
import AuditDialog from '@/components/AuditDialog.vue';
import ModalPrint from './ModalPrint.vue';
import ModalMigrateAgreement from './ModalMigrateAgreement.vue';
import RocketMenu from './RocketMenu.vue';

export default {
  name: 'AgreementHeader',
  components: {
    AuditDialog,
    ModalMigrateAgreement,
    ModalPrint,
    RocketMenu,
  },
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    Open: {
      type: Boolean,
      required: true,
    },
    SelectedDate: {
      type: String,
      required: false,
    },
    Solution: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      mdiContentCopy,
      mdiArchive,
      mdiSeal,
      localAgreement: {
        id: this.AgreementId,
      },
      selected: false,
      tableHeadingSelector: 'Renewal Date',
      dialogAudit: false,
      summary: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapGetters('currencies', ['currencySymbol']),
    ...mapGetters('incomeLines', ['incomeLines']),
    ...mapState('auth', ['userId']),
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('users', ['users']),
    availableActions() {
      switch (this.Agreement.status) {
        case 'Delivered':
          if (this.isAdmin && !this.Agreement.verified) {
            return ['Print', 'Renew', 'Duplicate', 'Upgrade', 'Archive', 'History', 'Verify'];
          }
          return ['Print', 'Renew', 'Duplicate', 'Upgrade', 'Archive', 'History'];
        case 'Declined':
          return ['Print', 'Duplicate', 'Archive', 'History'];
        case 'Accepted':
        case 'Work In Progress':
          if (this.isAdmin && !this.Agreement.verified) {
            return ['Print', 'Duplicate', 'Deliver', 'Archive', 'History', 'Verify'];
          }
          return ['Print', 'Duplicate', 'Deliver', 'Archive', 'History'];
        case 'Confirmed':
          if (this.isAdmin) {
            return ['Print', 'Duplicate', 'Accept', 'Reject', 'Archive', 'History', 'Verify'];
          }
          return ['Print', 'Duplicate', 'Archive', 'History'];
        default:
        case 'Draft':
          if (this.isAdmin) {
            return ['Print', 'Duplicate', 'Confirm', 'Accept', 'Reject', 'Archive', 'Delete'];
          }
          return ['Print', 'Duplicate', 'Confirm', 'Reject', 'Archive', 'Delete'];
      }
    },
    isAdmin() {
      return this.isInRole('Administrators');
    },
  },
  watch: {
    Agreement: {
      immediate: true,
      deep: true,
      handler(agreement) {
        this.localAgreement = agreement;
      },
    },
    Open: {
      immediate: true,
      deep: true,
      handler(open) {
        if (open) {
          this.refresh();
        }
      },
    },
  },
  created() {
    this.isLoading = true;
    this.localAgreement = this.Agreement;
    this.isLoading = false;
  },
  mounted() {
    this.refresh();
    this.$root.$on('data:incomeLine-updated', (agreementItem) => {
      if (this.Agreement.id === agreementItem.agreementId) {
        debounce(() => {
          this.refresh();
        }, 500)();
      }
    });
    this.$root.$on('refresh.agreement', () => {
      debounce(() => {
        this.refresh();
      }, 500)();
    });
    this.LOAD_users();
  },
  beforeDestroy() {
    this.$root.$off('data:incomeLine-updated', this.refresh);
    this.$root.$off('refresh.agreement', this.refresh);
  },
  methods: {
    ...mapActions('agreements', [
      'UPDATE_agreement',
      'DUPLICATE_agreement',
      'UPGRADE_agreement',
      'LOAD_summary',
      'RENEW_agreement',
    ]),
    ...mapActions('incomeLines', ['LOAD_incomeLines', 'LOAD_incomeLinesWithCosts']),
    ...mapActions('users', ['LOAD_users']),
    async refresh() {
      this.summary = await this.LOAD_summary(this.Agreement.id);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$root.$emit('toast:notify', 'Copied to clipboard');
        })
        .catch((error) => {
          this.$root.$emit('toast:error', 'Failed to copy to clipboard');
          this.$log.error('Failed to copy text: ', error);
        });
    },
    async onMenuClick(item) {
      switch (item) {
        case 'Accept':
          this.accept();
          break;
        case 'Confirm':
          this.confirm();
          break;
        case 'Duplicate':
          this.duplicate();
          break;
        case 'Renew':
          this.renew();
          break;
        case 'Reject':
          this.decline();
          break;
        case 'Delete':
          this.remove();
          break;
        case 'Deliver':
          this.deliver();
          break;
        case 'Print':
          this.print();
          break;
        case 'Migrate':
          this.migrate();
          break;
        case 'Upgrade':
          this.upgrade();
          break;
        case 'Send':
          this.$root.$emit('toast:notify', 'Not yet implemented');
          break;
        case 'Archive':
          this.archive();
          break;
        case 'History':
          this.history();
          break;
        case 'Verify':
          this.verify();
          break;
        default:
          this.$root.$emit('toast:notify', 'Unknown menu item');
      }
    },
    async archive() {
      this.isLoading = true;
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        isActive: false,
      });
      this.isLoading = false;
      this.$emit('refresh');
    },
    async unarchive() {
      this.isLoading = true;
      const res = await this.$confirm('Are you sure you want to unarchive this agreement?');
      if (!res) return;
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        isActive: true,
      });
      this.isLoading = false;
      this.$emit('refresh');
    },
    async decline() {
      this.isLoading = true;
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        status: 'Declined',
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async accept() {
      this.isLoading = true;
      const acceptedDate = this.Agreement.acceptedDate ?? new Date();
      if (!this.localAgreement.originalAcceptedDate) {
        await this.UPDATE_agreement({
          id: this.Agreement.id,
          originalAcceptedDate: acceptedDate,
        });
      }
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        status: 'Work In Progress',
        acceptedDate,
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async confirm() {
      // check if a client has been set
      if ((this.Solution?.contactId ?? this.Agreement?.contactId) === undefined) {
        // eslint-disable-next-line no-alert
        window.alert('Please select a client before accepting the agreement');
        return;
      }
      if ((this.Solution?.personId ?? this.Agreement?.personId) === undefined) {
        // eslint-disable-next-line no-alert
        window.alert('Please select a person before accepting the agreement');
        return;
      }

      this.isLoading = true;
      const incomeLines = await this.LOAD_incomeLinesWithCosts({ agreementId: this.Agreement.id, isActive: true });
      const costLines = incomeLines.flatMap((item) => item.costLines);
      const missingSuppliers = costLines.filter((item) => !item.supplierId);
      if (missingSuppliers.length > 0) {
        // eslint-disable-next-line no-alert
        window.alert('Please select a supplier for each line item before accepting the agreement');
        return;
      }
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        status: 'Confirmed',
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async renew() {
      this.isLoading = true;
      await this.RENEW_agreement({
        id: this.Agreement.id,
        lastRenewalDate: new Date(),
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async deliver() {
      this.isLoading = true;
      const deliverdDate = this.Agreement.deliveredDate ?? new Date();
      if (!this.localAgreement.originalDeliveredDate) {
        await this.UPDATE_agreement({
          id: this.Agreement.id,
          originalDeliveredDate: deliverdDate,
        });
      }
      await this.UPDATE_agreement({
        id: this.Agreement.id,
        status: 'Delivered',
        deliveredDate: deliverdDate,
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async duplicate() {
      const res = await this.$confirm('Are you sure you want to duplicate this agreement?');
      if (res) {
        this.isLoading = true;
        this.$emit('collapse');
        await this.$nextTick();
        await new Promise((r) => setTimeout(r, 200));
        const agreement = { ...this.localAgreement };
        await this.DUPLICATE_agreement(agreement);
        this.isLoading = false;
        this.$emit('refresh');
      }
    },
    async upgrade() {
      const res = await this.$confirm('Are you sure you want to upgrade this agreement?');
      if (res) {
        this.isLoading = true;
        this.$emit('collapse');
        await this.$nextTick();
        await new Promise((r) => setTimeout(r, 200));
        const agreement = { ...this.localAgreement };
        await this.UPGRADE_agreement(agreement);
        this.isLoading = false;
        this.$emit('refresh');
        await this.refresh();
      }
    },
    async history() {
      this.dialogAudit = true;
    },
    async migrate() {
      this.$refs.ModalMigrateAgreement.open();
    },
    async print() {
      this.$refs.ModalPrint.open();
    },
    async verify() {
      this.isLoading = true;
      const res = await this.$confirm('Are you sure you want to verify this agreement?');
      if (!res) return;

      const { users } = this;
      const user = users.find((u) => u.createdById === this.userId);

      await this.UPDATE_agreement({
        id: this.Agreement.id,
        verified: new Date(),
        verifiedById: this.userId,
        verifiedBy: user?.displayName ?? user?.createdBy,
        verifiedByEmail: user?.createdBy,
      });
      this.isLoading = false;
      this.$emit('refresh');
      await this.refresh();
    },
    async remove() {
      const res = await this.$confirm('Are you sure you want to delete this agreement?');
      if (res) {
        this.isLoading = true;
        await this.UPDATE_agreement({
          id: this.localAgreement.id,
          status: 'Deleted',
          isDeleted: true,
        });
        this.isLoading = false;
        this.$emit('refresh');
        this.$emit('collapse');
      }
    },
  },
};
</script>
