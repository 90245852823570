export default {
  agreements: [],
  agreementHeaders: [],
  agreement: undefined,
  stages: [
    'Draft',
    'Prospecting',
    'Negotiating',
    'Confirmed',
    'Work In Progress',
    'Declined',
    'Delivered',
    'Cancelled',
  ],
  stagesForActions: [
    {
      text: 'Draft',
      value: 'Draft',
    },
    {
      text: 'Prospecting',
      value: 'Prospecting',
    },
    {
      text: 'Negotiating',
      value: 'Negotiating',
    },
    {
      text: 'Confirmed',
      value: 'Confirmed',
      disabled: true,
    },
    {
      text: 'Work In Progress',
      value: 'Work In Progress',
      disabled: true,
    },
    {
      text: 'Delivered',
      value: 'Delivered',
      disabled: true,
    },
    {
      text: 'Cancelled',
      value: 'Cancelled',
      disabled: true,
    },
  ],
  summary: undefined,
};
