<template>
  <div v-if="localAgreement">
    <v-row dense>
      <v-col cols="4" class="d-flex">
        <ButtonIconToggle
          v-if="disabled"
          v-model="isLocked"
          :disabled="!isAdmin"
          :trueIcon="mdiLock"
          :falseIcon="mdiLockOpen"
        />
        <div v-if="disabled" class="mr-1" />
        <v-textarea
          auto-grow rows="1" dense v-model="localAgreement.reference" outlined label="Agreement Name"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="localAgreement.responsiblePersonId"
          dense
          :items="users"
          data-cy="EditSolution-responsiblePersonId"
          :label="$$t('responsiblePersonId')"
          item-text="displayName"
          item-value="id"
          :disabled="isDisabled"
          @change="updateResponsiblePerson"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          dense
          :items="users"
          :disabled="isDisabled"
          data-cy="EditSolution-dealEnablerId"
          :label="$$t('dealEnablerId')"
          item-text="displayName"
          item-value="createdById"
          v-model="localAgreement.dealEnablerId"
          @change="updateDealEnabler"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
          label="Invoice #"
          v-model="localAgreement.invoiceNumber"
          :disabled="isDisabled"
          dense
        />
      </v-col>
      <v-col cols="1" class="d-flex">
        <v-text-field
          dense
          block
          class="text-field-term"
          data-cy="FormQuote-poNumber"
          :disabled="isDisabled"
          label="PO #"
          v-model="localAgreement.poNumber"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          auto-select-first
          dense
          data-cy="FormQuote-currencyCode"
          :disabled="isDisabled"
          :label="$$t('currencyCode')"
          :items="currencies"
          item-text="name"
          item-value="code"
          v-model="localAgreement.currencyCode"
        />
      </v-col>
      <!-- <v-col cols="2">
        <v-select
          dense
          :items="users"
          data-cy="EditSolution-ownerId"
          :label="$$t('owner')"
          item-text="displayName"
          item-value="ownerId"
          v-model="localAgreement.ownerId"
          @change="updateOwner"
        />
      </v-col> -->
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-select
          dense
          :items="stagesForActions"
          label="Stage"
          v-model="localAgreement.status"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker
          :filled="false"
          dense
          block
          data-cy="Agreement-acceptedDate"
          label="Accepted Date"
          v-model="localAgreement.acceptedDate"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker
          v-model="localAgreement.expectedDeliveryDate"
          :filled="false"
          dense
          data-cy="Agreement-expectedDeliveryDate"
          label="Expected Delivery Date"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker
          :filled="false"
          dense
          data-cy="Agreement-deliveredDate"
          label="Delivered Date"
          v-model="localAgreement.deliveredDate"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker
          v-model="localAgreement.lastRenewalDate"
          @input="onLastRenewalDateChange"
          :filled="false"
          dense
          block
          data-cy="Agreement-renewalDate"
          label="Last Renewal Date"
        />
      </v-col>
      <v-col cols="2">
        <DatePicker
          :filled="false"
          dense
          block
          data-cy="Agreement-cancellationDate"
          label="Cancellation Date"
          v-model="localAgreement.cancellationDate"
        />
      </v-col>
      <v-col cols="6" class="d-flex">
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="text-field-term"
          :disabled="isDisabled"
          label="Term"
          hint="Client terms in months"
          v-model="localAgreement.term"
          type="number"
        />
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="FormQuote-noticeTerm"
          :disabled="isDisabled"
          label="Notice Term"
          hint="Client notice terms in months"
          v-model="localAgreement.noticeTerm"
          type="number"
        />
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="FormQuote-renewalTerm"
          :disabled="isDisabled"
          label="Renewal Term"
          hint="Client renewal terms in months"
          v-model="localAgreement.renewalTerm"
          type="number"
        />
      </v-col>
      <v-col cols="2">
        <NullableTextField
          dense
          block
          data-cy="FormQuote-endDate"
          disabled
          label="Agreement End Date"
          :value="formatDate(localAgreement.endDate)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AgreementItems :Agreement="localAgreement" :IsActive="IsActive" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs>
          <v-tab>Notes</v-tab>
          <v-tab>
            <v-badge right color="primary">
              <span slot="badge">{{ comments.length }}</span>
              Comments
            </v-badge>
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div v-if="disabled" v-html="localAgreement.notes" />
                <vue-editor
                  v-else
                  :editorToolbar="customToolbar"
                  :disabled="isDisabled"
                  label="Notes"
                  outlined
                  v-model="localAgreement.notes"
                  counter="2000"
                >
                </vue-editor>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat v-if="localAgreement.id">
              <v-card-text>
                <AgreementComments :Agreement="localAgreement" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="localAgreement.id">
      <v-col cols="12">
        <AgreementAttachments :Agreement="localAgreement" />
      </v-col>
    </v-row>
    <!-- <v-row v-if="localAgreement.migrationContractId">
      <v-col cols="12">
        Migration Info: Contract:
        <router-link
          class="migration-link"
          :to="{
          name: 'contracts-EditContract',
          query: { id: localAgreement.migrationContractId },
        }">
          Open Migrated Contract
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="localAgreement.migrationQuoteId">
      <v-col cols="12">
        <router-link
          class="migration-link"
          :to="{
          name: 'quotes-EditQuote',
          query: { id: localAgreement.migrationQuoteId },
        }">
          Open Migrated Quote
        </router-link>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiLock, mdiLockOpen } from '@mdi/js';
import { debounce } from 'lodash';
import { VueEditor } from 'vue2-editor';
import ButtonIconToggle from '@/components/ButtonIconToggle.vue';
import AgreementAttachments from './AgreementAttachments.vue';
import AgreementItems from './AgreementItems.vue';
import AgreementComments from './AgreementComments.vue';

export default {
  name: 'Agreement',
  module: 'Solutions',
  components: {
    AgreementAttachments,
    AgreementComments,
    AgreementItems,
    // eslint-disable-next-line vue/no-unused-components
    ButtonIconToggle,
    VueEditor,
  },
  props: {
    AgreementId: {
      type: String,
      required: true,
    },
    IsActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLocked: true,
      localAgreement: {
        id: this.AgreementId,
      },
      mdiLock,
      mdiLockOpen,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapGetters('exchangeRates', ['currencies']),
    ...mapGetters('agreements', ['stagesForActions']),
    ...mapState('comments', ['comments']),
    ...mapState('users', ['users']),
    disabled() {
      if (!this.localAgreement) return true;
      return !(this.localAgreement?.status === 'New'
        || this.localAgreement?.status === 'Draft'
        || this.localAgreement?.status === 'Certain'
        || this.localAgreement?.status === 'Negotiating'
        || this.localAgreement?.status === 'Prospecting');
    },
    isAdmin() {
      return this.isInRole('Administrators');
    },
    isDisabled() {
      if (!this.isLocked) {
        return false;
      }
      return this.disabled;
    },
  },
  watch: {
    Agreement: {
      immediate: true,
      deep: true,
      handler() {
        this.localAgreement = this.Agreement;
        this.debouncedRefresh();
      },
    },
    localAgreement: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localAgreement);
        this.debouncedSave(this.localAgreement);
      },
    },
    'localAgreement.cancellationDate': {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;

        if (newValue === null && oldValue === undefined) return;

        this.$log.info('Cancellation date updated');
        this.cancellationDateUpdated();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.refresh();
    this.isLocked = this.disabled;
    this.$root.$on('refresh.agreement', () => {
      this.debouncedRefresh();
    });
  },
  beforeDestroy() {
    this.$root.$off('refresh.agreement', this.refresh);
  },
  methods: {
    ...mapActions('agreements', [
      'UPDATE_agreement',
      'LOAD_agreement',
    ]),
    ...mapActions('incomeLines', [
      'LOAD_incomeLines',
    ]),
    ...mapActions('comments', ['LOAD_comments']),
    ...mapActions('exchangeRates', ['LOAD_exchangeRatesForAgreement']),
    /** Method used to save the item
     */
    async save(agreement) {
      this.$log.info(`Saving agreement: ${agreement.agreementNumber}`);
      this.isLoading = true;
      await this.UPDATE_agreement(agreement);
      this.isLoading = false;
    },
    onLastRenewalDateChange() {
      this.$log.info('Last renewal date changed');
      this.debouncedRefresh();
      setTimeout(() => {
        this.$root.$emit('refresh.agreementHeader');
      }, 2000);
    },
    // eslint-disable-next-line func-names
    debouncedRefresh: debounce(function () {
      this.refresh();
    }, 1000),
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (agreement) {
      this.$log.info(`Debounced saving agreement: ${agreement.agreementNumber}`);
      if (this.isLoading) {
        this.$log.info('Skipping save as agreement is loading');
        return;
      }
      this.save(agreement);
    }, 500),
    async refresh() {
      this.isLoading = true;
      this.localAgreement = await this.LOAD_agreement(this.AgreementId);
      await this.LOAD_exchangeRatesForAgreement(this.AgreementId);
      await this.LOAD_comments(this.AgreementId);
      this.isLoading = false;
    },
    cancellationDateUpdated() {
      setTimeout(() => {
        this.LOAD_incomeLines({ agreementId: this.AgreementId, isActive: this.IsActive });
      }, 2000);
    },
    updateOwner() {
      const user = this.users.find((u) => u.createdById === this.localAgreement.ownerId);
      this.localAgreement.owner = user?.displayName ?? user?.createdBy;
      this.localAgreement.ownerEmail = user?.createdBy;
    },
    updateResponsiblePerson() {
      const user = this.users.find((u) => u.id === this.localAgreement.responsiblePersonId);
      this.localAgreement.responsiblePerson = user?.displayName ?? user?.createdBy;
      this.localAgreement.responsiblePersonEmail = user?.createdBy;
    },
    updateDealEnabler() {
      const user = this.users.find((u) => u.createdById === this.localAgreement.dealEnablerId);
      this.localAgreement.dealEnabler = user?.displayName ?? user?.createdBy;
      this.localAgreement.dealEnablerEmail = user?.createdBy;
    },
  },
};
</script>
<style>
.text-field-term {
  width: 150px;
  display: inline-block;
  margin-right: 5px !important;
}
</style>
