<template>
  <v-autocomplete
    v-model="searchResult"
    return-object
    :search-input.sync="search"
    :loading="isLoading"
    item-text="description"
    item-value="link"
    outlined
    hide-no-data
    :prepend-inner-icon="mdiMagnify"
    dense
    class="mt-7 ml-5"
    :items="items"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <router-link :to="item.link" class="text-decoration-none">
            {{ item.description }}
          </router-link>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mdiMagnify } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'SearchBar.vue',
  mixins: [loading],
  data: () => ({
    mdiMagnify,
    search: '',
    searchResult: undefined,
    results: [],
  }),
  computed: {
    items() {
      return this.results.map((result) => ({
        id: result.id,
        description: result.type === 'agreement' ?
          `Agreement: ${result.agreementNumber} | ${result.reference}` :
          `Solution: ${result.solutionNumber}: ${result.name} | ${result.contact}`,
        link: {
          name: 'app-SearchRedirect',
          query: {
            solutionId: result.type === 'agreement' ? result.solutionId : result.id,
            type: result.type,
            agreementId: result.type === 'agreement' ? result.id : undefined,
          },
        },
      }));
    },
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     this.searchResult = undefined;
    //   },
    // },
    searchResult(val) {
      if (val) {
        this.searchResult = undefined;
        this.search = '';
        this.$router.push(val.link);
      }
    },
    search(val) {
      // Items have already been loaded
      // if (this.results.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      if (val === null || val === undefined || val === '') {
        this.results = [];
        return;
      }

      this.isLoading = true;

      Promise.all([
        (async () => {
          // eslint-disable-next-line max-len
          const url = `odata/agreements?$filter=contains(tolower(agreementNumber), tolower('${val}')) AND IsActive eq true AND IsDeleted eq false`;
          const { data } = await this.$http.get(url);
          const agreements = data.value.map((agreement) => ({
            ...agreement,
            type: 'agreement',
          }));
          agreements.forEach((agreement) => {
            this.results.push(agreement);
          });
        })(),
        (async () => {
          // eslint-disable-next-line max-len
          const url = `odata/agreements?$filter=contains(tolower(reference), tolower('${val}')) AND IsActive eq true AND IsDeleted eq false`;
          const { data } = await this.$http.get(url);
          const referenceAgreements = data.value.map((agreement) => ({
            ...agreement,
            type: 'agreement',
          }));
          referenceAgreements.forEach((agreement) => {
            this.results.push(agreement);
          });
        })(),
        (async () => {
          // eslint-disable-next-line max-len
          const url = `odata/solutions?$filter=contains(tolower(contact), tolower('${val}')) AND IsActive eq true AND IsDeleted eq false`;
          const { data } = await this.$http.get(url);
          const solutions = data.value.map((solution) => ({
            ...solution,
            type: 'solution',
          }));
          solutions.forEach((solution) => {
            this.results.push(solution);
          });
        })(),
        (async () => {
          // eslint-disable-next-line max-len
          const { data } = await this.$http.get(`odata/solutions?$filter=contains(tolower(solutionNumber), tolower('${val}'))`);
          const solutions = data.value.map((solution) => ({
            ...solution,
            type: 'solution',
          }));
          solutions.forEach((solution) => {
            this.results.push(solution);
          });
        })(),
        (async () => {
          // eslint-disable-next-line max-len
          const { data } = await this.$http.get(`odata/solutions?$filter=contains(tolower(name), tolower('${val}'))`);
          const solutions = data.value.map((solution) => ({
            ...solution,
            type: 'solution',
          }));
          solutions.forEach((solution) => {
            this.results.push(solution);
          });
        })(),
      ]).then(() => {})
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  methods: {
  },
};
</script>
