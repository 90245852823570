import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { o } from 'odata';
import { clarity } from 'clarity-js';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';

import { components } from '@codehq/aurora-app-core/src';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
// eslint-disable-next-line max-len
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import { GChart } from 'vue-google-charts/legacy';
import VueOwinAuth from '@codehq/vue-owin-auth/src/vueOwinAuth';
import VueMask from 'v-mask';
import VuetifyConfirm from 'vuetify-confirm';
import vueDebounce from 'vue-debounce';
import VNumeric from 'vuetify-numeric';

import { abortController } from './abortController';

import App from './App.vue';
import AutoSelect from './components/AutoSelect.vue';
import AutoComplete from './components/AutoComplete.vue';
import CurrencyTextField from './components/CurrencyTextField.vue';
import DatePicker from './components/DatePicker.vue';
import ExportToExcel from './components/ExportToExcel.vue';
import MultipleTextArea from './components/MultipleTextArea.vue';
import MultipleTextField from './components/MultipleTextField.vue';
import NullableTextField from './components/NullableTextField.vue';
import NullableNumberField from './components/NullableNumberField.vue';
import './open-telemetry';

import vuetify from './plugins/vuetify';
import formatting from './plugins/formatting';

// import refreshOnLogin from './plugins/refresh-on-login';
// import refreshOnOrgChange from './plugins/refresh-on-org-change';
// import VueSignalR from './plugins/vue-signalr';
import store from './store';
import { router, routes } from './router';
import { i18n, messages } from './i18n';

export const eventBus = new Vue();

Vue.config.productionTip = false;

// register global components
Vue.use(VueAxios, axios);
Vue.use(VueOwinAuth, {
  url: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(VuetifyConfirm, { vuetify });

const { ImageUpload } = components;

Vue.use(VueMask);

Vue.use(vueDebounce);

// register app components
Vue.component(AutoSelect.name, AutoSelect);
Vue.component(AutoComplete.name, AutoComplete);
Vue.component(CurrencyTextField.name, CurrencyTextField);
Vue.component(NullableTextField.name, NullableTextField);
Vue.component(DatePicker.name, DatePicker);
Vue.component(ExportToExcel.name, ExportToExcel);
Vue.component(MultipleTextArea.name, MultipleTextArea);
Vue.component(MultipleTextField.name, MultipleTextField);
Vue.component(NullableNumberField.name, NullableNumberField);
Vue.component(ProgressLinearTimeout.name, ProgressLinearTimeout);
Vue.component('GChart', GChart);

Vue.use(VNumeric);

// register directives
Vue.directive('format-number', {
  bind(el) {
    // Get the input element
    const input = el.querySelector('input');
    // Create a formatter function that adds commas as thousand separators
    // and a decimal separator if necessary
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      useGrouping: true,
    });

    // Add an event listener to the input element to format the value
    // as it is being updated
    input.addEventListener('input', () => {
      // Get the unformatted value
      let { value } = input;

      // Remove any non-numeric characters
      value = value.replace(/\D/g, '');

      // Check if the value is a valid number
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value)) {
        // Format the number and update the value of the input element
        input.value = formatter.format(value);
      }
    });
  },
});

// Vue.use(VueSignalR, `${process.env.VUE_APP_APIURL}/signalr/stockTicker`);

// register aurora components and plugin
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(ImageUpload.name, ImageUpload);

// setup app plugins
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});
Vue.use(formatting);

// Vue.use(refreshOnLogin);

// Vue.use(refreshOnOrgChange);

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

const abortAxios = abortController(axios);

Vue.prototype.$abortAxios = abortAxios;

o(process.env.VUE_APP_APIURL);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  i18n,
  mounted() {
    this.$log.debug('Locales:');
    this.$log.debug(messages);
    this.$log.debug('Routes:');
    this.$log.debug(routes);
    clarity.start({
      projectId: 'iuo5ugwvkh',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
    });
    clarity.consent();
  },
}).$mount('#app');
