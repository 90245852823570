import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('agreement', 'agreements');

const customActions = {
  async LOAD_agreements({ commit, rootGetters }, { solutionId, isActive }) {
    const data = await odata.o(rootGetters)
      .get('agreements')
      .query({
        $filter: `IsDeleted eq false AND SolutionId eq ${solutionId} AND IsActive eq ${isActive}`,
        $orderby: 'created desc',
      });
    commit('SET_agreements', data);
    return data;
  },
  async LOAD_agreement({ commit, rootGetters }, agreementId) {
    const data = await odata.o(rootGetters)
      .get(`agreements(${agreementId})`)
      .query();
    commit('SET_agreement', data);
    return data;
  },
  async LOAD_agreementWithItems({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`agreements(${id})`)
      .query({
        $expand: 'Items',
      });
    return data;
  },
  async LOAD_MIN_agreements({ commit, rootGetters }, { isActive }) {
    const data = await odata.o(rootGetters)
      .get('agreements')
      .query({
        $filter: `IsDeleted eq false AND IsActive eq ${isActive}`,
        $select: 'Id, AgreementNumber,MigrationContractSectionId,MigrationContractId',
        $orderby: 'created desc',
      });
    commit('SET_agreements', data);
    return data;
  },
  async LOAD_AllAgreements({ rootGetters }, includeArchived) {
    let $filter = 'IsDeleted eq false';
    if (!includeArchived) {
      $filter += ' AND IsActive eq true';
    }
    const data = await odata.o(rootGetters)
      .get('agreements')
      .query({
        $filter,
        $expand: 'Solution,Attachments($filter=IsDeleted eq false)',
        $orderby: 'created desc',
      });
    return data;
  },
  async LOAD_ClientAgreements({ rootGetters }, clientId) {
    const data = await odata.o(rootGetters)
      .get('agreements')
      .query({
        $filter: `IsDeleted eq false AND (Solution/ContactId eq ${clientId} OR ContactId eq ${clientId})`,
        $expand: 'Solution,Lines',
        $orderby: 'Created desc',
      });
    return data;
  },
  async LOAD_GetSummaryForClient({ rootGetters }, { currencyCode, contactId }) {
    const data = await odata.o(rootGetters)
      .get(`agreements/GetSummaryForClient(currencyCode='${currencyCode}',contactId=${contactId})`)
      .query();
    return data;
  },
  async LOAD_GetSummaryForSupplier({ rootGetters }, { currencyCode, supplierId }) {
    const data = await odata.o(rootGetters)
      .get(`agreements/GetSummaryForSuppliers(currencyCode='${currencyCode}',supplierId=${supplierId})`)
      .query();
    return data;
  },
  async LOAD_manageAgreements({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('agreements')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'Solution',
        $orderby: 'created desc',
      });
    return data;
  },
  // eslint-disable-next-line max-len
  async LOAD_getSolutionAgreements({ commit, rootGetters }, { solutionId, currencyCode, isActive }) {
    const data = await odata.o(rootGetters)
      // eslint-disable-next-line max-len
      .get(`agreements/GetHeaders(solutionId=${solutionId},currencyCode='${currencyCode}',isActive=${isActive})`)
      .query();
    commit('SET_agreementHeaders', data);
    return data;
  },
  async UPDATE_agreement({ commit, rootGetters, dispatch }, agreement) {
    // const existingAgreement = state.agreements.find((a) => a.id === agreement.id);

    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .patch(`agreements(${id})`, agreement)
      .query();
    commit('SET_agreement', result);
    dispatch('LOAD_summary', id);
    dispatch('solutions/LOAD_summary', agreement.solutionId, { root: true });
  },
  async UNARCHIVE_agreement({ commit, rootGetters, dispatch }, agreement) {
    // const existingAgreement = state.agreements.find((a) => a.id === agreement.id);

    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .post(`agreements(${id})/unarchive`)
      .query();
    commit('SET_agreement', result);
    dispatch('LOAD_summary', id);
    dispatch('solutions/LOAD_summary', agreement.solutionId, { root: true });
  },
  async DUPLICATE_agreement({ rootGetters }, agreement) {
    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .post(`agreements(${id})/Duplicate`)
      .query();
    return result;
  },
  async RENEW_agreement({ rootGetters }, agreement) {
    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .post(`agreements(${id})/Renew`)
      .query();
    return result;
  },
  async UPGRADE_agreement({ rootGetters }, agreement) {
    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .post(`agreements(${id})/Upgrade`)
      .query();
    return result;
  },
  async MIGRATE_agreement({ rootGetters }, { agreement, solutionId }) {
    const { id } = agreement;
    const result = await odata.o(rootGetters)
      .post('agreements/Migrate', {
        request: {
          agreementId: id,
          solutionId,
        },
      })
      .query();
    return result;
  },
  async LOAD_summary({ commit, rootGetters }, id) {
    if (id === undefined) {
      console.warn('agreement id is undefined');

      return null;
    }
    const data = await odata.o(rootGetters)
      .get(`agreements(${id})/getSummary`)
      .query();
    commit('SET_summary', data);

    return data;
  },
};

export default { ...actions, ...customActions };
