/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

// Store functionality
import modules from './modules';

function httpPlugin(http) {
  return (s) => {
    // Attach the http client to the store
    // eslint-disable-next-line no-param-reassign
    s.$http = http;
    // eslint-disable-next-line no-param-reassign
    s.$axios = http;
  };
}
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'auth', 'organizations', 'currencies', 'controls'],
});
Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  modules,
  plugins: [
    vuexLocal.plugin,
    httpPlugin(axios),
  ],
});

export default store;

export const useStore = () => store;
